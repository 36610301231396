.flag-image {
    float: left;
    clear: both;
    padding: 0.5rem;

    .emojiFlag {
        border: 1px solid rgba($color: #000000, $alpha: 0.25);
    }
}

.node-address {
    float: right;
    clear: both;
    padding: 0.5rem;

    .address-text {
        float: left;
        padding: 8px 0;
        font-weight: bold;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: navy;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    clear: both;

    .node-address-footer {
        clear: both;
        color: gray;
    }
}