.home-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .home-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .home-container p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .home-container a {
    font-size: 1.2rem;
    color: blue;
    text-decoration: none;
  }
  
  .home-container a:hover {
    text-decoration: underline;
  }
  

  .write-container {
    display: flex;
  }
  
  .card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
  }
  