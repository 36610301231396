
.ag-header-cell-label {
    justify-content: center;
    text-align: center;
    text-wrap: wrap;
}

.hosts {
    .table-header {
        font-weight: bold !important;
        font-size: 12px;
        text-align: center;
        text-wrap: wrap;
        display: flex;
        justify-content: center;
    }

    .cell-style {
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 20px;
    }

    .flex-vertical {
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
    }

    .justify-content-center {
        justify-content: center;
        text-align: center;
    }

    .justify-content-flex-start {
        justify-content: flex-start;
    }

    .node-status-cell {
        font-size: 18px;
    }

    .active-node {
        color: green;
    }

    .inactive-node {
        color: red;
    }

    .cpu-model-text {
        white-space: pre-wrap;
        text-align: center;
    }

    .instance-size-text {
        white-space: pre-wrap;
        text-align: center;
    }

    .next-arrow-icon {
        font-size: 1.5rem;
        cursor: pointer;
    }

    // Cell Rules
    .text-center {
        text-align: center;
    }

    .green-background {
        background-color: green;
        color: white;
    }

    .red-background {
        background-color: #ff0000;
        color: white;
    }


    .visible-on-row-hover {
        display: none;
    }

    .ag-row-hover {
        .visible-on-row-hover {
            display: block;
        }

    }
}