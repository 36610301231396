.moment-info {
    margin: 0;
    padding: 0.85rem 1rem;
    background-color: white;
    border-radius: 0.5rem;
    color: black;
    font-size: 0.8rem;

    .label {
        text-transform: uppercase;
        color: gray;
    }

    .ledger-count {
        font-weight: bold;
    }

    .moment-container{
        border-left: 1px solid lightgray;
    }
}