.host-reg-info {

    .title {
        font-weight: bold;
        padding-bottom: 10px;
    }

    .table-container {
        border-collapse: collapse;

        tbody {
            tr {
                // td {
                //     padding: 15px;
                // }

                &:not(tr:last-child) {
                    border-bottom: 1px solid lightgray;
                }

                &:first-child td:first-child {
                    border-top-left-radius: 10px;
                }

                &:first-child td:last-child {
                    border-top-right-radius: 10px;
                }

                &:last-child td:first-child {
                    border-bottom-left-radius: 10px;
                }

                &:last-child td:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    .header {
        padding: 10px;
        background-color: rgb(240, 240, 250);
        font-weight: bold;
        color: gray;
        text-align: left;
        vertical-align: middle;
    }

    .value {
        padding: 2px 10px;
        background-color: white;
        text-align: left;
        vertical-align: middle;
    }

    .inner-table-header {
        float: left;
        width: 75px;
        text-align: center;
        border-bottom: 1px solid gray;
        padding: 0 !important;
    }

    .inner-table-value {
        float: left;
        width: 75px;
        text-align: center;
        padding: 0 !important;
    }
}

.token-id {
    font-size: small;
}
@media only screen and (max-width: 1024px) {
    .host-reg-info .title {
        font-size: 14px; /* Adjust font size as needed */
    }

    .host-reg-info .value {
        font-size: 12px; /* Adjust font size as needed */
    }

    .host-reg-info .inner-table-header,
    .host-reg-info .inner-table-value {
        font-size: 10px; /* Adjust font size as needed */
    }
}

@media only screen and (max-width: 1024px) {
    .host-reg-info .title,
    .host-reg-info .header,
    .host-reg-info .value {
        font-size: 14px; /* Adjust font size as needed */
    }

    .host-reg-info .inner-table-header,
    .host-reg-info .inner-table-value {
        font-size: 10px; /* Adjust font size as needed */
    }
}

