@import '../assets/scss/theme.scss';

.layout-wrapper {
    background-color: $background-color;

    .header-bg {
        height: $topbar-height;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: $topbar-height;
            width: 100%;
            padding: 15px;
            object-fit: contain;
            background-size: cover;
            background-repeat: repeat;
        }
    }

    .top-bar {
        height: $topbar-height;
        background-color: $background-color !important;
        border-bottom: 2px solid $primary-color;

        .content {
            font-size: 1.8rem;
            color: $white-color;
            text-transform: uppercase;

            .left-content {
                padding: 1.5rem;
                text-transform: uppercase;

                &.network-name {
                    margin-left: 3rem;
                }
            }

            .right-content {
                float: right;
                padding-right: 10px;
            }
        }
    }

    .left-menu {
        min-height: calc(100vh - #{$topbar-height});
        color: $white-color;
        box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }

    .page-content {
        min-height: calc((100vh) - #{($topbar-height)});
        background-color: #ececec;
        padding: 1rem;
    }

    footer {
        height: $footer-height;
        padding: 15px;
        text-align: right;
        font-size: 0.9rem;
        color: $white-color;
        background: linear-gradient(90deg, $primary-color 0%, $background-color 100%);

        .geveo-link {
            color: $white-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}