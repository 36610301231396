.available-leases-container {
    .title {
        font-weight: bold;
        padding-bottom: 10px;
    }

    .lease-container {
        background-image: linear-gradient(-20deg, #07112D 0%, #0037FF 100%);
        color: white;
        margin-bottom: 2rem;
        border-radius: 10px;

        .lease-token-text {
            padding: 1.5rem;
            font-size: 1rem;
        }

        table {
            width: 100%;
            border-collapse: collapse;

            tr {
                &:not(tr:last-child) {
                    border-bottom: 1px solid white;
                }

                &:last-child td:last-child{
                    border-bottom-right-radius: 10px ;
                    border-bottom: 0;
                }

                td {
                    padding: 0.5rem;
                }

                td.header {
                    width: 10vw;
                }

                td.value {
                    background-color: white;
                    border-bottom: 1px solid lightgray;
                    color: black;
                }
            }
        }
    }
}