.copy-button-container{
    .copy-icon-tooltip {
        font-size: 12px;
        color: unset;
        cursor: pointer;

        .copy-button {
            margin: 0px;
            padding: 0px;
            color: inherit;
            opacity: 0.3;

            &:hover {
                opacity: 1;
            }
        }

        .done-all {
            color: #2b9e19;
            opacity: 1;
        }
    }
}