@import '../../assets/scss/theme.scss';

.left-menu-bar {

    .menu-item {
        .content {
            padding: 1.2rem 1rem;

            &:hover {
                background-color: $primary-color;
                border-radius: 0 100px 100px 0;
                margin-right: 20px;
                cursor: pointer;
            }
        }

        .selected {
            background-color: $primary-color;
            border-radius: 0 100px 100px 0;
            margin-right: 20px;
        }

        a {
            text-decoration: unset;
            color: unset;
        }

        .menu-icon {
            padding: 0 10px;
        }
    }
}