.host {
    vertical-align: top;

    .host-reg-info-container {
        // float: left;
        margin-right: 20px;
    }

    .available-leases-container {
        // float: left;
    }
}