.registry-container {

    .table-container {
        border-collapse: collapse;
        width: 100%;

        tbody {
            tr {
                td {
                    padding: 15px;
                }

                &:not(tr:last-child) {
                    border-bottom: 1px solid lightgray;
                }

                &:first-child td:first-child {
                    border-top-left-radius: 10px;
                }

                &:first-child td:last-child {
                    border-top-right-radius: 10px;
                }

                &:last-child td:first-child {
                    border-bottom-left-radius: 10px;
                }

                &:last-child td:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    .header {
        padding: 10px;
        width: 30vw;
        background-color: rgb(240, 240, 250);
        font-weight: bold;
        color: gray;
        text-align: left;
        vertical-align: middle;
    }

    .value {
        padding: 10px;
        background-color: white;
        text-align: left;
        vertical-align: middle;
    }
}