.gridview-container {
    width: 100%;

    .MuiDataGrid-footerContainer {
        background-color: white;

        .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
            margin: 0;
        }
    }
}


.row-style {
    //background-color: #9be3c7!important;
    padding-top: 0;

}
