.host-header-container {
    min-height: 65px;
    background-color: white;
    padding: 0 5px;
    border-radius: 10px;
    margin-bottom: 10px;

    .container-left {
        float: left;

        .flag-image {
            float: left;
            padding: 0.5rem;
            margin-top: 4px;

            .emojiFlag {
                border: 1px solid rgba($color: #000000, $alpha: 0.25);
            }
        }

        .address-text {
            float: left;
            font-size: 1.2rem;
            font-weight: bold;
            padding: 5px 5px 0 5px;
        }

        .status-indicator {
            float: left;
            margin-top: 8px;

            .active {
                color: green;
            }

            .inactive {
                color: #f4772e;
            }
        }

        .title-footer {
            margin: 5px 65px;
            color: gray;
        }
    }

    .container-right {
        float: right;
        margin-top: 10px;
        padding: 5px 10px;
        color: white;
        background-image: linear-gradient(-20deg, #07112D 0%, #0037FF 100%);
        border-radius: 5px;

        .amount {
            float: left;
            font-size: 1.5rem;
        }

        .symbol {
            float: left;
            margin-top: 8px;
            margin-left: 5px;
            font-size: 1rem;
        }
    }
}