.modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 30px;
    max-width: 90%;
    /* Adjust as needed */
    max-height: 95%;
    /* Adjust as needed */
    overflow-y: auto;
    /* Enable scrolling if content exceeds modal height */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: rgb(138, 142, 142);
    background: none;
    border: none;
    cursor: pointer;
}

.sub-section {
    margin-top: 10px;
}

.min-section {
    padding: 5px 0;
}

.des-title {
    text-transform: uppercase;
    font-size: small;
    font-weight: 600;
    color: rgb(138, 142, 142);
    margin-bottom: 0;
}

.description {
    font-size: small;
    margin-bottom: 0;
}